<template>
    <div class="m-p">
       <TicketDetails />
    </div>
 </template>
 <script>
 import { defineAsyncComponent } from 'vue'
 export default {
 name: 'Ticket Details',
 components: {
    TicketDetails: defineAsyncComponent( () => import('@/components/Profile/Support/TicketDetails.vue') ),
 },
 }
 </script>